const baseUrlWithSlash =
  // "https://18.220.55.89.nip.io/";
  process.env.REACT_APP_API_BASE_URL;

// "https://beta.18.220.55.89.nip.io/";
// "http://localhost:8080/";

const apiUrls = {
  users: {
    login: "users/login",
    getUsers: "users/",
    add: "users/add",
    resetPassword: "users/resetPassword",
    changePassword: "users/changePassword",
    addUserAddress: "/userAddress/addUserAddress",
    getUserAdress: "/userAddress/getUserAddresses",
    updateProfilePicture: "/users/addProfilePicture",
    getUserPassword: "/users/getUserPassword",
  },
  batches: {
    list: "batch/getBatch",
    add: "batch/addBatch",
  },
  medicines: {
    list: "medicine/get",
    add: "medicine/addMedicine",
    addStock: "medicine/addMedicineStock",
    listStocks: "medicine/getMedicineStocks",
    listOneStock: "medicine/getOneMedicineStock",
    buyingList: "medicine/buyingList",
    search: "medicine/search",
  },
  stockAdjustment: {
    list: "stockAdjustment/getStockAdjustment",
    add: "stockAdjustment/addStockAdjustment",
    getStocks: "stockAdjustment/getStocks",
  },
  communication: {
    sendOtp: "communication/sendOtp",
    verifyOtp: "communication/verifyOtp",
  },
  user: {
    add: "users/add",
    list: "users",
  },
  notification: {
    add: "notifications/addNotification",
    list: "notifications/getNotifications",
    count: "notifications/getUnreadCount",
    update: "notifications/updateNotifications",
    readAll: "notifications/readAll",
    deleteAll: "notifications/deleteAll",
  },
  cart: {
    get: "userCart/get",
    getCartItems: "userCart/getCartItems",
    addToCart: "userCart/add",
    clearCart: "userCart/clearCart",
    addFreeItem: "userCart/addFreeItems",
  },
  uploadFiles: "/file/upload",
  orders: {
    add: "orders/add",
    addWithQr: "orders/addWithQr",
    get: "orders/getAll",
    getOne: "orders/getOne",
    createInvoice: "orders/createInvoice",
    repeatOrder: "orders/addOrderToCart",
    uploadEwayBill: "orders/uploadEwaybill",
    updateOrder: "orders/update",
    returnItems: "orders/returnItems",
  },
  configs: {
    add: "configs/add",
    get: "configs/get",
  },
  getPresignedUrl: "common/getPresignedUrl",
  cancelOrder: "common/deleteOrder",
  reports: {
    inventory: "report/inventory",
    dashboard: "report/dashboard",
    doctor: "report/doctor",
    customer: "report/customer",
    category: "report/category",
    top10Products: "report/top10Products",
  },
  constants: {
    getMedicine: `constants/getMedicineCategories`,
    getDoctors: `constants/getDoctorSpecializations`,
  },
  discount: {
    add: `discounts/add`,
    get: `discounts/get`,
  },
  stockPrice: {
    add: "productUserPrice/add",
    get: "productUserPrice/get",
  },
  razorpay: {
    createPaymentLink: "razorpay/createPaymentLink",
    getPaymentLinks: "razorpay/getPaymentLinks",
    createOrder: "razorpay/createOrder",
    capturePayment: "razorpay/capturePayment",
  },
  achievements: {
    add: "achievements/add",
    get: (queryString) => `achievements/get${queryString}`,
    delete: (id) => `achievements/delete?_id=${id}`,
  },
  top: {
    get: (queryString) => `report/stats${queryString}`,
  },
  linkedDoctors: {
    get: "linkedDoctors/list",
    create: "linkedDoctors/create",
    delete: (id) => `linkedDoctors/delete?id=${id}`,
  },
};

export { baseUrlWithSlash as apiBaseUrl };
export default apiUrls;

// error.respond.data.err.msg
