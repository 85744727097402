import { CFade } from "@coreui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import authRoutes from "./routes/authRoutes";
import "./scss/style.scss";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/loader";
import UserProfileContextProvider from "./context/userProfile";
import { getUser } from "./pages/User/api";
import { SET_USER_DATA } from "./pages/auth/actions";

const Loading = ({ loading }) => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const App = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.commonReducer.loader);

  return (
    <>
      <UserProfileContextProvider>
        <Loader loading={loading} />
        <ToastContainer
          position="bottom-left"
          autoClose={4000}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Switch>
          {authRoutes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )}
                />
              )
            );
          })}
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </UserProfileContextProvider>
    </>
  );
};

export default App;

export { Loading };
