import { SET_CART_DATA } from "./actions";

const initialState = {
  cartItems: {},
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_DATA:
      return { ...state, cartItems: action.payload };

    default:
      return state;
  }
};
export default cart;
