import { SET_USER_DATA } from "./actions";
import {SET_NOTIFICATION_DATA} from "./actions"
const initialState = {
  userDetails: {},
  notify:{},
};

const auth = (state = initialState, action) => {
  console.log(action.type, "action.type");
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userDetails: action.payload };

      case SET_NOTIFICATION_DATA:
        return { ...state, notify: action.payload };
  

    default:
      return state;
  }
};
export default auth;
