import { SET_LOADER, SIDEBAR } from "./actions";

const initialState = {
  sidebarShow: "responsive",
  loader: false,
  toggle: true,
};

const commonReducer = (
  state = initialState,
  { type, payload, abc, ...rest }
) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case SET_LOADER:
      return { ...state, loader: payload };
    case SIDEBAR:
      return { ...state, toggle: abc };

    default:
      return state;
  }
};
export default commonReducer;
