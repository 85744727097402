import React from "react";
import ResetPassword from "src/pages/auth/resetPassword";
const Login = React.lazy(() => import("../pages/auth/login/index"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const AppInventoryReports = React.lazy(() =>
  import("../pages/ApplicationPages/inventoryReport")
);
const AppCustomerReport = React.lazy(() =>
  import("../pages/ApplicationPages/customerReport")
);
const AppCategoryReport = React.lazy(() =>
  import("../pages/ApplicationPages/categoryReport")
);
const AppTotalSalesReport = React.lazy(() =>
  import("../pages/ApplicationPages/totalSalesReport")
);
const ListUsers = React.lazy(() => import("../pages/ApplicationPages/doctorList"));
const authRoutes = [
  { path: "/login", name: "Login Page", component: Login },
  {
    path: "/forgetPassword",
    name: "Forget Password",
    component: ForgetPassword,
  },
  {
    path: "/password/:type",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/app-reports/inventory",
    exact: true,
    name: "Inventory Reports",
    component: AppInventoryReports,
  },
  {
    path: "/app-reports/totalSales",
    exact: true,
    name: "Total Sales Reports",
    component: AppTotalSalesReport,
  },
  {
    path: "/app-reports/customer",
    name: "Customer Report",
    component: AppCustomerReport,
  },
  {
    path: "/app-reports/category",
    name: "Category Report",
    component: AppCategoryReport,
  },
  {
    path: "/app-doctors/list",
    name: "Doctor List",
    component: ListUsers,
  },
];

export default authRoutes;
