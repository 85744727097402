import EmptyCart from "./empty-cart.png";
import Medicine from "./atorfit.png";
import Oops from "./oops.png";
import Notification from "./notify.png";
import noImage from "./noImage.png";
import birthday from "./birthday.png";
import dartboardOne from "./dartboard.png";
import dartboardTwo from "./dartboard2.png";
import dartboardThree from "./dartboard3.png";
import dartboardFour from "./dartboard4.png";
import cash from "./cash.png";
import demoQR from "./demoQR.png";

export const Images = {
  emptyCart: EmptyCart,
  medicine: Medicine,
  notification: Notification,
  noImage: noImage,
  birthday: birthday,
  dartboardOne: dartboardOne,
  dartboardTwo: dartboardTwo,
  dartboardThree: dartboardThree,
  dartboardFour: dartboardFour,
  cash: cash,
  demoQR: demoQR,
};
