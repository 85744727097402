import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CForm,
  CRow,
} from "@coreui/react";
import Images from "src/constants/images/logo.png";
import { changePassword, resetPassword } from "./apis";
import { SET_LOADER } from "src/redux/actions";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PasswordInput from "src/components/formFields/passwordInput";
import localStorageConstants from "src/constants/localstorageConstants";
import validations from "src/utils/validations";
import ShowImage from "src/components/showImage";
import { SET_USER_DATA } from "../actions";

const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { type } = useParams();
  const { state: { userId } = {} } = useLocation();

  const user = localStorage.getItem(localStorageConstants.userId);

  useEffect(() => {
    if ((type !== "change" && !userId) || (type === "change" && !user)) {
      history.push("/login");
    }
  }, []);

  const onClickResetPassword = () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("The two passwords does not match");
      return;
    }
    if (
      !validations.password.test(newPassword) ||
      !validations.password.test(confirmNewPassword)
    ) {
      toast.error(
        "Password must contain atleast 8 characters, one lowercase letter, one uppercase letter and special character"
      );
      return;
    }

    dispatch({ type: SET_LOADER, payload: true });
    if (type === "change") {
      changePassword(
        { oldPassword, newPassword, user_id: userId },
        (data) => {
          if (data?.status === "success") {
            history.push("/dashboard");
            toast.success("Password has been changed");
            dispatch({ type: SET_LOADER, payload: false });
          } else {
            dispatch({ type: SET_LOADER, payload: false });
            toast.error(data?.message);
          }
        },
        () => {
          dispatch({ type: SET_LOADER, payload: false });
          toast.error("Old password is incorrect");
        }
      );
    } else {
      resetPassword(
        { newPassword, user_id: userId },
        (data) => {
          if (data?.status === "success") {
            dispatch({ type: SET_USER_DATA, payload: data?.data });
            history.push("/dashboard");
            toast.success("Password has been resetted");
            dispatch({ type: SET_LOADER, payload: false });
          } else {
            dispatch({ type: SET_LOADER, payload: false });
            toast.error(data?.message);
          }
        },
        () => {
          dispatch({ type: SET_LOADER, payload: false });
          toast.error("Network Error");
        }
      );
    }
  };

  return (
    <div
      className="c-app c-default-layout justify-content-center align-items-center hide-text-selection"
      style={{
        backgroundColor: "#1A2038",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <row className="d-flex align-items-center justify-content-center">
          <CCol md="9">
            <CCardGroup>
              <CCard
                className="text-white d-md-down-none"
                style={{ width: "44%" }}
              >
                <img
                  src={Images}
                  alt="img"
                  style={{ width: "80%", margin: "auto" }}
                />
                {/* <ShowImage 
                  src={Images}
                  alt="img"
                  style={{ height: "75%",
                   marginTop: "50px" }}
                /> */}
              </CCard>

              <CCard className="p-3">
                <CCardBody>
                  <CForm>
                    <h1
                      className="text-center mt-1 mb-3"
                      style={{ fontSize: 26 }}
                    >
                      {type === "change" ? "Change" : "Reset"} Password
                    </h1>

                    {type === "change" ? (
                      <PasswordInput
                        value={oldPassword}
                        setValue={setOldPassword}
                        title="Old Password"
                        className="mb-3"
                        placeholder="Old Password"
                      />
                    ) : null}
                    <PasswordInput
                      value={newPassword}
                      setValue={setNewPassword}
                      title="New Password"
                      placeholder="New Password"
                      className="mb-3"
                    />
                    <PasswordInput
                      value={confirmNewPassword}
                      setValue={setConfirmNewPassword}
                      title="Password"
                      placeholder="Password"
                      className="mb-3"
                    />

                    <CRow>
                      <CCol
                        xs="6"
                        lg="12"
                        className="justify-content-center align-item-center"
                      >
                        <CButton
                          className="btn-info px-4 ml-0 mt-4"
                          onClick={onClickResetPassword}
                          style={{ background: "#7d4e9e", border: "none" }}
                        >
                          {type === "change" ? "Change" : "Reset"} Password
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </row>
      </div>
    </div>
  );
};

export default ResetPassword;
