import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserProfileProvider from "./provider";

const UserProfileContextProvider = ({ children }) => {
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const [isProfileVerified, setIsProfileVerified] = useState(
    userDetails?.is_profile_verified
  );

  useEffect(() => {
    setIsProfileVerified(userDetails?.is_profile_verified || false);
  }, [userDetails, isProfileVerified]);

  console.log(userDetails, "usfdd");
  return (
    <UserProfileProvider.Provider
      value={{
        isProfileVerified,
        setIsProfileVerified,
      }}
    >
      {children}
    </UserProfileProvider.Provider>
  );
};

export default UserProfileContextProvider;
