import axios from "axios";
import { apiBaseUrl } from "./apis";
import axiosRetry from "axios-retry";
import { store } from "src/redux/store";
import { toast } from "react-toastify";
import { SET_LOADER } from "src/redux/actions";

const apiInstance = () => {
  const api = axios.create({
    baseURL: apiBaseUrl,
  });
  axiosRetry(api, { retries: 3 });

  api.interceptors.request.use(async (config) => {
    let accessToken = "";
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    }
    console.log("REQUEST", config);
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.data?.err?.message === "userId is required") {
        store.dispatch({ type: SET_LOADER, payload: false });
        return;
      }
      if (error?.message) {
        store.dispatch({ type: SET_LOADER, payload: false });
        toast.error(error?.response?.data?.err?.message);
        return;
      }
      if (error?.response?.data?.err?.message) {
        store.dispatch({ type: SET_LOADER, payload: false });
        toast.error(error?.response?.data?.err?.message);
        return;
      }
      if (error?.response?.data?.message) {
        store.dispatch({ type: SET_LOADER, payload: false });
        toast.error(error?.response?.data?.message);
        return;
      }
      throw error;
    }
  );

  return api;
};

const apiClient = apiInstance();

export default apiClient;
