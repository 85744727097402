import React from "react";
import ReactImageFallback from "react-image-fallback";
import { Images } from "src/assets/images";

function ShowImage(props) {
  const { src = "", className = "", alt = "", value = "", style = {} } = props;

  return (
    <div>
      <ReactImageFallback
        src={src}
        fallbackImage={Images.noImage}
        initialImage={Images.noImage}
        alt={alt}
        className={className}
        value={value}
        style={style}
      />
    </div>
  );
}

export default ShowImage;
